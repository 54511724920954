import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { GET_ZAEHLER } from './graphql';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        padding: 20,
    },
    section: {
        flexDirection: 'column',
        fontSize: '11px',
        minHeight: 30, // Set a minimum height for all rows
    },
    header: {
        borderBottom: '1px solid black',
        paddingBottom: 7,
        fontSize: 13,
    },
    row: {
        flexDirection: 'row',
        padding: 5,
    },
    evenRow: {
        backgroundColor: '#f0f0f0', // Light gray for even rows
    },
    oddRow: {
        backgroundColor: '#ffffff', // White for odd rows
    },
    cell: {
        padding: 2,
    },
    parzelle: {
        width: '20%',
    },
    name: {
        width: '25%',
    },
    zaehlernummer: {
        width: '25%',
    },
    zaehlerart: {
        width: '20%',
    },
    letzteEichung: {
        width: '10%',
    },
});

const Eichdatenliste = () => {
    const { loading, error, data, refetch } = useQuery(GET_ZAEHLER);

    // Da wir ein pachtSet, also ein array of Pachten, geliefert bekommen, müssen wir
    // das Pachtende filtern; dieses muss grösser als heute sein
    const today = new Date();

    const zaehlerList = data ? data.zaehler
    .filter(item => item.energieart == 'Strom')
    .filter(item => item.deinstallationAm == null)
    .filter(item => !item.parzelle.standort.toLowerCase().includes('test'))
    .map(item => ({
        parzelle: item.parzelle.standort,
        name: item.parzelle.pachtSet.filter(pacht => {
            const pachtende = new Date(pacht.pachtende);
            return pachtende > today;
        })[0]?.paechter.name,
        zaehlernummer: item.zaehlernummer.includes("nachtragen") ? "" : item.zaehlernummer,
        //zaehlerart: item.parzelle.pachtSet[0]?.paechter.stromzaehlerart,
        zaehlerart: item.parzelle.pachtSet.filter(pacht => {
            const pachtende = new Date(pacht.pachtende);
            return pachtende > today;
        })[0]?.paechter.stromzaehlerart,
        letzteEichung: item.letzteEichung,
    })) : [];

    // sort by parzelle
    zaehlerList.sort((a, b) => {
        let paechterA = a.parzelle.toLowerCase();
        let paechterB = b.parzelle.toLowerCase();
        
        if (paechterA < paechterB) return -1;
        if (paechterA > paechterB) return 1;
        return 0;
    });


    return (
        <PDFViewer>
            <Document>
                <Page size="A4" orientation="landscape" style={styles.page}>
                    <View style={styles.section}>
                        {/* Render table header */}
                        <View style={styles.row} fixed>
                            <Text style={[styles.cell, styles.header, styles.parzelle]}>Parzelle</Text>
                            <Text style={[styles.cell, styles.header, styles.name]}>Name</Text>
                            <Text style={[styles.cell, styles.header, styles.zaehlernummer]}>Zählernummer Strom</Text>
                            <Text style={[styles.cell, styles.header, styles.zaehlerart]}>Zählerart</Text>
                            <Text style={[styles.cell, styles.header, styles.letzteEichung]}>Letzte Eichung</Text>
                        </View>

                        {/* Render rows */}
                        {zaehlerList.map((item, index) => (
                            <View key={index} style={[styles.row, index % 2 === 0 ? styles.evenRow : styles.oddRow]}>
                                <Text style={[styles.cell, styles.parzelle]} wrap>{item.parzelle}</Text>
                                <Text style={[styles.cell, styles.name]} wrap>{item.name}</Text>
                                <Text style={[styles.cell, styles.zaehlernummer]} wrap>{item.zaehlernummer}</Text>
                                <Text style={[styles.cell, styles.zaehlerart]} wrap>{item.zaehlerart}</Text>
                                <Text style={[styles.cell, styles.letzteEichung]} wrap>{item.letzteEichung}</Text>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default Eichdatenliste;
